<template>
  <div class="align-items-center">

    <transition name="desconectado">
      <div v-if="showDisconnected" class="banner">
          <div class="content">
            <i class="pi pi-exclamation-triangle mr-2"></i>
            <span> Desconectado del servidor</span>
          </div>
      </div>
    </transition>

  </div>
</template>

<script>
export default {
  name: "WebConnected",
  data() {
    return {
      showDisconnected: false,
    };
  },
  methods: {
    updateOnlineStatus(e) {
      // this.$emit('web-conected', e.type)
      this.showDisconnected = e.type === "online" ? false : true;
    },
  },
  mounted: function () {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    
  },
  beforeUnmount() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>

<style lang="scss">
@import "./src/App.scss";


.banner{
    background: #e83030;
    color: #fff;
    height: 35px;
    position: relative;
}

.banner .content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 90000;
}

.desconectado-enter-active,
.desconectado-leave-active {
  transition: opacity 0.5s ease;
}

.desconectado-enter-from,
.desconectado-leave-to {
  opacity: 0;
}
</style>
