

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faBars,
  faUser,
  faBell,
  faCog,
  faChevronDown,
  faSignOutAlt,
  faSignInAlt,
  faUserCircle,
  faTimes,
  faUserCog,
  faTh,
  faCreditCard,
  faWallet,
  faShieldAlt,
  faTools,
  faFilePdf,
  faCaretRight,
  faComment,
  faCheckCircle,
  faQuestion,
  faBook,
  faPlusSquare,
  faEdit,
  faTrash,
  faSearch,
  faUndo,
  faFile,
  faArrowLeft,
  faArrowRight,
  faTimesCircle,
  faHome,
  faChartLine,
  faPaperclip,
  faLock,
  faCloudUploadAlt,
  faRetweet,
  faCheck,
  faCloudDownloadAlt,
  faTable,
  faHandHoldingUsd,
  faExclamationTriangle,
  faCashRegister,
  faFileInvoiceDollar,
  faFileAlt,
  faRecycle,
  faClipboardList,
  faEye,
  faSync,
  faSpinner,
  faCalculator,
  faPlus,
  faCalendarAlt,
  faHomeUser,
  faDatabase,
  faDoorOpen,
  faPowerOff,
  faCarCrash,
  faExclamation,
  faExclamationCircle,
  faBuildingShield,
  faGlasses,
  faStop,
  faTruckArrowRight,
  faMapLocation,
  faLocationDot,
  faSatellite,
  faListDots,
  faSubtract,
  faDotCircle,
  faKey,
  faFileShield,
  faAddressCard,
  faUsers,
  faFingerprint,
  faPeopleGroup,
  faBoxesStacked,
  faCube,
  faCubes,
  faHouseLock,
  faFileContract,
  faRightToBracket,
  faRightFromBracket,
  faCopyright,
  faCar,
  faGasPump,
  faTag,
  faFileExcel,
  faBugs,
  faBug,
  faCarSide,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBars,
  faUser,
  faBell,
  faCog,
  faChevronDown,
  faSignOutAlt,
  faSignInAlt,
  faUserCircle,
  faTimes,
  faUserCog,
  faTh,
  faCreditCard,
  faWallet,
  faShieldAlt,
  faTools,
  faFilePdf,
  faCaretRight,
  faComment,
  faCheckCircle,
  faQuestion,
  faBook,
  faPlusSquare,
  faEdit,
  faTrash,
  faSearch,
  faUndo,
  faFile,
  faArrowLeft,
  faArrowRight,
  faCheckCircle,
  faTimesCircle,
  faHome,
  faChartLine,
  faPaperclip,
  faLock,
  faCloudUploadAlt,
  faCloudDownloadAlt,
  faRetweet,
  faCheck,
  faTable,
  faHandHoldingUsd,
  faExclamationTriangle,
  faCashRegister,
  faFileInvoiceDollar,
  faFilePdf,
  faFileAlt,
  faPaperclip,
  faRecycle,
  faClipboardList,
  faEye,
  faSync,
  faSpinner,
  faCalculator,
  faPlus,
  faCalendarAlt,
  faHomeUser,
  faDatabase,
  faDoorOpen,
  faPowerOff,
  faCarCrash,
  faExclamation,
  faExclamationCircle,
  faBuildingShield,
  faGlasses,
  faStop,
  faTruckArrowRight,
  faMapLocation,
  faLocationDot,
  faSatellite,
  faListDots,
  faSubtract,
  faDotCircle,
  faKey,
  faFileShield,
  faAddressCard,
  faUsers,
  faFingerprint,
  faPeopleGroup,
  faBoxesStacked,
  faCube,
  faCubes,
  faHouseLock,
  faFileContract,
  faRightToBracket,
  faRightFromBracket,
  faCopyright,
  faCar,
  faGasPump,
  faTag,
  faFileExcel,
  faBugs,
  faBug,
  faCarSide,
  faCheckDouble
);

