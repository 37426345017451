import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/pages/auth/Login.vue'),
    meta: { guestOnly: true },
  },
  { 
    path: "/dashboard",
    component: () => import(/* webpackChunkName: "base" */ "@/pages/users/base/Layout.vue"),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/users/Dashboard.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Consultas' }] },
      },
      //-------------------- EXCEL ---------------------------------
      {
        path: '/tablaexportar',
        name: 'tablaexportar',
        component: () => import(/* webpackChunkName: "tablaexportar" */ '@/pages/users/tablaexportar/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Excel' }] },
      },
      //-------------------- BUSCADOR ---------------------------------
      {
        // /users/:username/posts/:postId
        path: '/encontrado/tabla',
        name: 'encontradotabla',
        props: true,
        component: () => import(/* webpackChunkName: "encontradotabla" */ '@/pages/users/buscador/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Buscador' }] },
      },
      {
        path: '/encontrado/vehiculo',
        name: 'encontradovehiculo',
        component: () => import(/* webpackChunkName: "encontradovehiculo" */ '@/pages/users/buscador/Vehiculo.vue'),
        meta: { authOnly: true, breadcrumb: [{ parent: 'Buscador', label: 'vehiculo' }] },
      },
      {
        path: '/encontrado/general',
        name: 'encontradogeneral',
        component: () => import(/* webpackChunkName: "encontradogeneral" */ '@/pages/users/buscador/General.vue'),
        meta: { authOnly: true, breadcrumb: [{ parent: 'Buscador', label: 'General' }] },
      },
      {
        path: '/entradas',
        name: 'entradas',
        component: () => import(/* webpackChunkName: "entradas" */ '@/pages/users/entradas/Start.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Ingresos' }] },
      },
      //-------------------- CUSTODIAS ---------------------------------

      {
        path: '/custodias',
        name: 'custodias',
        component: () => import(/* webpackChunkName: "custodias" */ '@/pages/users/custodias/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Custodias' }] },
      },
      {
        path: '/custodias/vehiculos',
        name: 'custvehiculosform',
        component: () => import(/* webpackChunkName: "custvehiculosform" */ '@/pages/users/custodias/Vehiculosform.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Custodias vehículos' }] },
      },
      {
        path: '/custodias/generales',
        name: 'custgeneralesform',
        component: () => import(/* webpackChunkName: "custgeneralesform" */ '@/pages/users/custodias/Generalesform.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Custodia generales' }] },
      },


      //-------------------- VEHICULOS ---------------------------------
      {
        path: '/entradas/vehiculos/tabla',
        name: 'entradastablavehiculo',
        component: () => import(/* webpackChunkName: "entradastablavehiculo" */ '@/pages/users/entradas/vehiculos/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ parent: 'Ingresos', label: 'Vehículos' }] },
      },
      {
        path: '/entradas/vehiculos/form',
        component: () => import(/* webpackChunkName: "entradasvehiculoform" */ '@/pages/users/entradas/vehiculos/Form.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "entradasvehiculoinfo" */ '@/pages/users/entradas/vehiculos/Info.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Ingresos', label: 'Vehículos' }] },
          },
          {
            path: '/entradas/vehiculos/vehiclulo',
            component: () => import(/* webpackChunkName: "entradasvehiculovehiclulo" */ '@/pages/users/entradas/vehiculos/Vehiclulo.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Ingresos', label: 'Vehículos' }] },
          },
          {
            path: '/entradas/vehiculos/inventario',
            component: () => import(/* webpackChunkName: "entradasvehiculoinventario" */ '@/pages/users/entradas/vehiculos/Inventario.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Ingresos', label: 'Vehículos' }] },
          },
          {
            path: '/entradas/vehiculos/custodia',
            component: () => import(/* webpackChunkName: "entradasvehiculocustodia" */ '@/pages/users/entradas/vehiculos/Custodia.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Ingresos', label: 'Vehículos' }] },
          },
          {
            path: '/entradas/vehiculos/asegurado',
            component: () => import(/* webpackChunkName: "entradasvehiculoasegurado" */ '@/pages/users/entradas/vehiculos/Asegurado.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Ingresos', label: 'Vehículos' }] },
          },
          {
            path: '/entradas/vehiculos/archivos',
            component: () => import(/* webpackChunkName: "entradasvehiculoarchivos" */ '@/pages/users/entradas/vehiculos/Archivos.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Ingresos', label: 'Vehículos' }] },
          },
          
        ]
      },
      //-------------------- GENERAL ---------------------------------
      {
        path: '/entradas/general/tabla',
        name: 'entradastablageneral',
        component: () => import(/* webpackChunkName: "entradastablageneral" */ '@/pages/users/entradas/general/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ parent: 'Entradas', label: 'General' }] },
      },
      {
        path: '/entradas/general/form',
        component: () => import(/* webpackChunkName: "entradasgeneralform" */ '@/pages/users/entradas/general/Form.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "entradasgeneralinfo" */ '@/pages/users/entradas/general/Infog.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Entradas', label: 'General' }] },
          },
          {
            path: '/entradas/general/detalle',
            component: () => import(/* webpackChunkName: "entradasgeneraldetalle" */ '@/pages/users/entradas/general/Detalle.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Entradas', label: 'General' }] },
          },
          {
            path: '/entradas/general/salvamento',
            component: () => import(/* webpackChunkName: "entradasgeneralsalvamento" */ '@/pages/users/entradas/general/Salvamento.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Entradas', label: 'General' }] },
          },
          {
            path: '/entradas/general/asegurado',
            component: () => import(/* webpackChunkName: "entradasgeneralasegurado" */ '@/pages/users/entradas/general/Aseguradog.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Entradas', label: 'General' }] },
          },
          {
            path: '/entradas/general/archivos',
            component: () => import(/* webpackChunkName: "entradasgeneralarchivos" */ '@/pages/users/entradas/general/Archivosg.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Entradas', label: 'General' }] },
          },
        ]
      },

      //-------------------- SALIDAS ---------------------------------
      //-------------------- VEHICULOS ---------------------------------

      {
        path: '/salidas/vehiculos/form',
        component: () => import(/* webpackChunkName: "salidasformvehiculos" */ '@/pages/users/salidas/vehiculos/Form.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "salidasvehiculoscaso" */ '@/pages/users/salidas/vehiculos/Caso.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Salidas', label: 'Vehículos' }] },
          },
          {
            path: '/salidas/vehiculos/imagenes',
            component: () => import(/* webpackChunkName: "salidasvehiculosimagenes" */ '@/pages/users/salidas/vehiculos/Imagenes.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Salidas', label: 'Vehículos' }] },
          },
          {
            path: '/salidas/vehiculos/salida',
            component: () => import(/* webpackChunkName: "salidasvehiculossalida" */ '@/pages/users/salidas/vehiculos/Salida.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Salidas', label: 'Vehículos' }] },
          },
        ]
      },
      //-------------------- GENERAL ---------------------------------

      {
        path: '/salidas/general/form',
        component: () => import(/* webpackChunkName: "salidasformgeneral" */ '@/pages/users/salidas/general/Form.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "salidasgeneralcaso" */ '@/pages/users/salidas/general/Caso.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Salidas', label: 'General' }] },
          },
          {
            path: '/salidas/general/imagenes',
            component: () => import(/* webpackChunkName: "salidasgeneralimagenes" */ '@/pages/users/salidas/general/Imagenes.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Salidas', label: 'General' }] },
          },
          {
            path: '/salidas/general/salida',
            component: () => import(/* webpackChunkName: "salidasgeneralsalida" */ '@/pages/users/salidas/general/Salida.vue'),
            meta: { authOnly: true, breadcrumb: [{ parent: 'Salidas', label: 'General' }] },
          },
        ]
      },
      // ----------------------------------------------------------------------------------------------------------
      {
        path: '/actualizacionmasiva',
        name: 'actualizacionmasiva',
        component: () => import(/* webpackChunkName: "actualizacionmasiva" */ '@/pages/users/actualizacionmasiva/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Actualizacion Masiva' }] },
      },
      //-------------------- SALIDAS OK ---------------------------------
      {
        path: '/salidasok',
        name: 'salidasok',
        component: () => import(/* webpackChunkName: "salidasok" */ '@/pages/users/salidasok/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Salidas finalizadas' }] },
      },
      {
        path: '/salidasok/generales',
        name: 'salidasokgenerales',
        component: () => import(/* webpackChunkName: "salidasokgenerales" */ '@/pages/users/salidasok/Generales.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Salidas finalizadas' }] },
      },
      {
        path: '/salidasok/vehiculos',
        name: 'salidasokvehiculos',
        component: () => import(/* webpackChunkName: "salidasokvehiculos" */ '@/pages/users/salidasok/Vehiculos.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Salidas finalizadas' }] },
      },
      {
        path: '/duplicados',
        name: 'duplicados',
        component: () => import(/* webpackChunkName: "duplicados" */ '@/pages/users/duplicados/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Unificar duplicados' }] },
      },
      
      
      
      
      //-------------------- TABLAS PRINCIPALES ---------------------------------
      {
        path: '/aseguradoras',
        name: 'aseguradoras',
        component: () => import(/* webpackChunkName: "aseguradoras" */ '@/pages/users/aseguradoras/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Aseguradoras' }] },
      },
      {
        path: '/aseguradoras/form',
        name: 'aseguradorasform',
        component: () => import(/* webpackChunkName: "aseguradorasform" */ '@/pages/users/aseguradoras/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Aseguradoras' }],},
      },
      {
        path: '/bodegas',
        name: 'bodegas',
        component: () => import(/* webpackChunkName: "bodegas" */ '@/pages/users/bodegas/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Bodegas' }] },
      },
      {
        path: '/bodegas/form',
        name: 'bodegasform',
        component: () => import(/* webpackChunkName: "bodegasform" */ '@/pages/users/bodegas/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Bodegas' }],},
      },
      {
        path: '/gruas',
        name: 'gruas',
        component: () => import(/* webpackChunkName: "gruas" */ '@/pages/users/gruas/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Gruas' }] },
      },
      {
        path: '/gruas/form',
        name: 'gruasform',
        component: () => import(/* webpackChunkName: "gruasform" */ '@/pages/users/gruas/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Gruas' }],},
      },
      {
        path: '/tiposingreso',
        name: 'tiposingreso',
        component: () => import(/* webpackChunkName: "tiposingreso" */ '@/pages/users/tiposingreso/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Tipos de Ingreso' }] },
      },
      {
        path: '/tiposingreso/form',
        name: 'tiposingresoform',
        component: () => import(/* webpackChunkName: "tiposingresoform" */ '@/pages/users/tiposingreso/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Tipos de Ingreso' }],},
      },
      {
        path: '/departamentos',
        name: 'departamentos',
        component: () => import(/* webpackChunkName: "departamentos" */ '@/pages/users/departamentos/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Departamentos' }] },
      },
      {
        path: '/departamentos/form',
        name: 'departamentosform',
        component: () => import(/* webpackChunkName: "departamentosform" */ '@/pages/users/departamentos/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Departamentos' }],},
      },
      {
        path: '/ciudades',
        name: 'ciudades',
        component: () => import(/* webpackChunkName: "ciudades" */ '@/pages/users/ciudades/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Ciudades' }] },
      },
      {
        path: '/ciudades/form',
        name: 'ciudadesform',
        component: () => import(/* webpackChunkName: "ciudadesform" */ '@/pages/users/ciudades/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Ciudades' }],},
      },
      {
        path: '/estados',
        name: 'estados',
        component: () => import(/* webpackChunkName: "estados" */ '@/pages/users/estados/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Estados' }] },
      },
      {
        path: '/estados/form',
        name: 'estadosform',
        component: () => import(/* webpackChunkName: "estadosform" */ '@/pages/users/estados/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Estados' }],},
      },
      {
        path: '/subestados',
        name: 'subestados',
        component: () => import(/* webpackChunkName: "subestados" */ '@/pages/users/subestados/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Sub-Estados' }] },
      },
      {
        path: '/subestados/form',
        name: 'subestadosform',
        component: () => import(/* webpackChunkName: "subestadosform" */ '@/pages/users/subestados/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Sub-Estados' }],},
      },
      {
        path: '/marcasvehiculos',
        name: 'marcasvehiculos',
        component: () => import(/* webpackChunkName: "marcasvehiculos" */ '@/pages/users/marcasvehiculos/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Marcas de vehículos' }] },
      },
      {
        path: '/marcasvehiculos/form',
        name: 'marcasvehiculosform',
        component: () => import(/* webpackChunkName: "marcasvehiculosform" */ '@/pages/users/marcasvehiculos/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Marcas de vehículos' }],},
      }, 
      {
        path: '/clasesvehiculos',
        name: 'clasesvehiculos',
        component: () => import(/* webpackChunkName: "clasesvehiculos" */ '@/pages/users/clasesvehiculos/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Clases de vehículos' }] },
      },
      {
        path: '/clasesvehiculos/form',
        name: 'clasesvehiculosform',
        component: () => import(/* webpackChunkName: "clasesvehiculosform" */ '@/pages/users/clasesvehiculos/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Clases de vehículos' }],},
      },
      {
        path: '/tiposcombustible',
        name: 'tiposcombustible',
        component: () => import(/* webpackChunkName: "tiposcombustible" */ '@/pages/users/tiposcombustible/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Tipos de combustible' }] },
      },
      {
        path: '/tiposcombustible/form',
        name: 'tiposcombustibleform',
        component: () => import(/* webpackChunkName: "tiposcombustibleform" */ '@/pages/users/tiposcombustible/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Tipos de combustible' }],},
      },

      //********************************************************************************************************** */
      {
        path: '/misdatos',
        name: 'misdatos',
        component: () => import(/* webpackChunkName: "misdatos" */ '@/pages/users/misdatos/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Mis Datos' }] },
      },
      {
        path: '/clave',
        name: 'clave',
        component: () => import(/* webpackChunkName: "clave" */ '@/pages/users/password/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Clave' }] },
      },
      {
        path: '/condiciones',
        name: 'condiciones',
        component: () => import(/* webpackChunkName: "condiciones" */ '@/pages/users/condiciones/Page.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Condiciones' }] },
      },
      {
        path: '/condiciones/form',
        name: 'condicionesform',
        component: () => import(/* webpackChunkName: "condicionesform" */ '@/pages/users/condiciones/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Editar Condiciones' }],},
      },
      {
        path: '/documentacion',
        name: 'documentacion',
        component: () => import(/* webpackChunkName: "documentacion" */ '@/pages/users/documentacion/Page.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Documentacion' }] },
      },
      {
        path: '/documentacion/form',
        name: 'documentacionform',
        component: () => import(/* webpackChunkName: "documentacionform" */ '@/pages/users/documentacion/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Editar Documentacion' }],},
      },
      {
        path: '/usuarios',
        name: 'usuarios',
        component: () => import(/* webpackChunkName: "usuarios" */ '@/pages/users/usuarios/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Usuarios' }] },
      },
      {
        path: '/usuarios/form',
        name: 'usuariosform',
        component: () => import(/* webpackChunkName: "usuariosform" */ '@/pages/users/usuarios/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Usuarios' }],},
      },
      {
        path: '/perfiles',
        name: 'perfiles',
        component: () => import(/* webpackChunkName: "perfiles" */ '@/pages/users/perfiles/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Perfiles' }] },
      },
      {
        path: '/perfiles/form',
        name: 'perfilesform',
        component: () => import(/* webpackChunkName: "perfilesform" */ '@/pages/users/perfiles/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Perfiles' }],},
      },
      {
        path: '/permisos',
        name: 'permisos',
        component: () => import(/* webpackChunkName: "permisos" */ '@/pages/users/permisos/Form.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Permisos' }] },
      },
      {
        path: '/menu',
        name: 'menu',
        component: () => import(/* webpackChunkName: "menu" */ '@/pages/users/menu/Index.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Menu' }] },
      },
      {
        path: '/systemerror',
        name: 'systemerror',
        component: () => import(/* webpackChunkName: "systemerror" */ '@/pages/users/errores/Tabla.vue'),
        meta: { authOnly: true, breadcrumb: [{ label: 'Errores del sistema' }] },
      },
    ]
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '@/pages/users/Logout.vue'),
    meta: { authOnly: true, breadcrumb: [{ label: 'logout' }] },
  },

    
]
    
      


  


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function isLoggedIn() {
  const token = localStorage.getItem("accessToken");
  if(token) {
    return true
  }
  return false 
}


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authOnly)) {
    if (!isLoggedIn()) {
      next({
        path: "/",
        redirect:  to.fullPath,
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guestOnly)) {
    if (isLoggedIn()) {
      next({
        path: "/dashboard",
        redirect: to.fullPath,
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
