import { createStore } from 'vuex'

export default createStore({
  state: {
    buscador:{
      placa: null,
      aseguradora: null,
      fecha_ini: null,
      fecha_fin: null,
      estado: null,
      filtro: 0,
    }
  },
  getters: {
  },
  mutations: {
    setBuscador (state, data) {
      state.buscador =  data
    },
  },
  actions: {
  },
  modules: {
  }
})
