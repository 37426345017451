<template>
  <div>
    <router-view />
  </div>
</template>

<script>


export default {
   
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
