import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config'


import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import AutoComplete from 'primevue/autocomplete';
import Badge from 'primevue/badge';
import BadgeDirective from "primevue/badgedirective";
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
import Column from 'primevue/column';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import FileUpload from 'primevue/fileupload';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
import InputSwitch from 'primevue/inputswitch';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import Menu from 'primevue/menu';
import MegaMenu from 'primevue/megamenu';
import Message from 'primevue/message';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Password from 'primevue/password';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import Sidebar from 'primevue/sidebar';
import ProgressSpinner from 'primevue/progressspinner';
import Steps from 'primevue/steps';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
import WebConected from "./components/WebConnected";

import 'primevue/resources/primevue.min.css'   	//required: PrimeVue components
import 'primeicons/primeicons.css'	 				//required: PrimeIcons
import 'primeflex/primeflex.css'
require("@/icons/fontawesome.js");


const app = createApp(App)

app.use(PrimeVue, {ripple: true})
app.use(store)
app.use(router)
app.use(ToastService);
app.use(ConfirmationService);

app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.directive('tooltip', Tooltip);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('AutoComplete', AutoComplete);
app.component('Badge', Badge);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Checkbox', Checkbox);
app.component('Chips', Chips);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Editor', Editor);
app.component('FileUpload', FileUpload);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('Galleria', Galleria);
app.component('Image', Image);
app.component('InputSwitch', InputSwitch);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Message', Message);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Password', Password);
app.component('RadioButton', RadioButton);
app.component('SelectButton', SelectButton);
app.component('Sidebar', Sidebar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Steps', Steps);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('Tree', Tree);
app.component('WebConected', WebConected);


app.mount('#app')
